  import { Injectable, inject, signal, WritableSignal  } from '@angular/core';
  import { DxDataGridComponent } from 'devextreme-angular';
  //import { AppServices } from 'src/app/app-services.service';
  import { EventService } from 'src/app/event.service';
  //import { BehaviorSubject } from 'rxjs';
  
  @Injectable({
    providedIn: 'root',
  })
  export class JpkServices {
  
    constructor(
    ) {
      const acc = sessionStorage.getItem('accesApp');
      if (acc) {
        this.accesApp.set(this.event.decryptString(acc));
      }
    }

    public queueTime = 2000;
    public dataGrid: DxDataGridComponent;
    public paramsGrid;
    public urlGrid;

    event = inject(EventService);

    public accesApp: WritableSignal<AppPermissionsGlobal[]> = signal(null);

  }

  interface AppPermissionsGlobal {
    PermissionGloId: number;
    Name: string;
    AppUserId: number;
    IsAccessGranted: boolean;
  }
  